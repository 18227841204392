// URL is provided inside API and middleware so we don't need to import it

export const redirectUrl =
  "https://bloomberg.tokenterminal.com/api/auth/bloomberg"

export function getAuthorizationUrl(clientId: string): URL {
  const authorizationUrl = new URL(
    "https://bsso.blpprofessional.com/as/authorization.oauth2"
  )
  authorizationUrl.searchParams.set("client_id", clientId)
  authorizationUrl.searchParams.set("scopes", "profile")
  authorizationUrl.searchParams.set("response_type", "code")
  authorizationUrl.searchParams.set("grant_type", "authorization_code")
  authorizationUrl.searchParams.set("redirectURI", redirectUrl)

  return authorizationUrl
}

export function getTokenUrl(
  clientId: string,
  clientSecret: string,
  code: string
): URL {
  const tokenUrl = new URL("https://bsso.blpprofessional.com/as/token.oauth2")
  tokenUrl.searchParams.set("grant_type", "authorization_code")
  tokenUrl.searchParams.set("code", code)
  tokenUrl.searchParams.set("client_id", clientId)
  tokenUrl.searchParams.set("client_secret", clientSecret)
  tokenUrl.searchParams.set("redirect_uri", redirectUrl)

  return tokenUrl
}
