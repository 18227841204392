import { APPLICATION } from "constants/app.mjs"
import { PriceDataType, PriceDataValue } from "data/socket"
import { useEffect, useMemo, useRef, useState } from "react"
import type { IGlobalApplication } from "./application"
import type {
  IBlpapiData,
  IDataSession,
  IGlobalData,
  IRealtimeDataSource,
} from "./data"

let applicationSDKPromise: Promise<IGlobalApplication> | null = null
let dataSDKPromise: Promise<IGlobalData | null> | null = null

function getApplicationSDK(): Promise<IGlobalApplication> {
  if (!applicationSDKPromise) {
    applicationSDKPromise = import("./application").then((mod) => mod.default)
  }

  return applicationSDKPromise
}

export function useApplicationSDK(): IGlobalApplication | null {
  const [sdk, setSDK] = useState<IGlobalApplication | null>(null)

  useEffect(() => {
    if (APPLICATION !== "bloomberg") {
      return
    }

    getApplicationSDK().then((sdk) => {
      setSDK(sdk)
    })
  }, [])

  return sdk
}

function getDataSDK(): Promise<IGlobalData | null> {
  if (!dataSDKPromise) {
    dataSDKPromise = import("./data")
      .then((mod) => mod.default)
      .catch((err) => {
        console.error("Importing Data SDK failed", err)
        return null
      })
  }

  return dataSDKPromise
}

export function useDataSDK(): IGlobalData | null {
  const [sdk, setSDK] = useState<IGlobalData | null>(null)

  useEffect(() => {
    if (APPLICATION !== "bloomberg") {
      return
    }

    getDataSDK()
      .then((sdk) => {
        setSDK(sdk)
      })
      .catch((err) => {
        console.error("Failed to set Data SDK", err)
      })
  }, [])

  return sdk
}

function useDataSession() {
  const sdk = useDataSDK()
  const [session, setSession] = useState<IDataSession | null>(null)

  useEffect(() => {
    async function createSession(dataSdk: IGlobalData) {
      try {
        const sesh = await dataSdk.createSession()
        if (!sesh) {
          console.warn("No session was received")
        }
        setSession(sesh)
      } catch (error) {
        console.error("Create data session failed", error)
      }
    }

    if (!sdk) {
      return
    }

    createSession(sdk)
  }, [sdk])

  return session
}

// Strings as types for clarity
type BBTicker = string
type BBProjectId = string

export type BBTickerMap = Readonly<Map<BBTicker, { id: BBProjectId }>>

// Allow single ticker or map of tickers. Map because it's easier to type and handle than for example [[ticker, id]] or an object
type ArrayMapOrString = BBTicker | BBTickerMap

type PricingInfoReturnType<T extends ArrayMapOrString> = T extends string
  ? PriceDataValue
  : PriceDataType

const BLOOMBERG_TICKER_INTERVAL_IN_SECONDS = 10

export function useCurrentBloombergPricingInfo<T extends ArrayMapOrString>(
  tickerOrTickerMap?: T | null
): PricingInfoReturnType<T> | null {
  const session = useDataSession()
  const rds = useRef<IRealtimeDataSource>()
  // Before RDS is hooked up, we capture the list of projects that we want to subscribe
  // to. Once the RDS is ready, we subscribe to all of them.
  const projectListCache = useRef<Set<string>>(new Set<string>())
  const [projectData, setProjectData] = useState(new Map<string, number>())

  const { tickers } = useMemo(() => {
    if (APPLICATION !== "bloomberg" || !tickerOrTickerMap) {
      return {}
    }

    const normalizedTickers: BBTicker[] =
      typeof tickerOrTickerMap === "string"
        ? [tickerOrTickerMap]
        : Array.from(tickerOrTickerMap.keys())

    // Filter out empty maps
    if (!normalizedTickers.length) {
      return {}
    }

    return {
      tickers: normalizedTickers.map(
        (tickerName) =>
          `${tickerName}?fields=LAST_PRICE&interval=${BLOOMBERG_TICKER_INTERVAL_IN_SECONDS}`
      ),
    }
  }, [tickerOrTickerMap])

  useEffect(() => {
    if (APPLICATION !== "bloomberg") {
      return
    }

    if (!session) {
      return
    }

    const realTimeDataSource = session.createRealtimeDataSource(function* () {
      while (true) {
        const data = yield
        if (data) {
          const castedData = data as unknown as IBlpapiData & {
            LAST_PRICE: number
          }
          if (typeof castedData.meta === "function") {
            const ticker: string | undefined = castedData
              .meta("subscriptionString")
              .split("?")[0]

            if (ticker) {
              if (typeof castedData.LAST_PRICE === "number") {
                // Not all payloads have LAST_PRICE. Initial(?) payload is some sort of handshake
                setProjectData((projectData) => {
                  projectData.set(ticker, castedData.LAST_PRICE)

                  return new Map(projectData)
                })
              }
            } else {
              // No idea if this check has value and can't know without testing
              console.warn("BB: no key was found")
            }
          }
        }
      }
    })

    projectListCache.current.forEach((projectId) => {
      realTimeDataSource.subscribe(projectId)
    })

    rds.current = realTimeDataSource

    return () => {
      realTimeDataSource.destroy()
      rds.current = undefined
    }
  }, [session])

  useEffect(() => {
    if (APPLICATION !== "bloomberg") {
      return
    }

    if (!tickers?.length) {
      return
    }

    const cache = projectListCache.current
    if (!rds.current) {
      tickers.forEach((ticker) => {
        cache.add(ticker)
      })
      return
    }

    const subsPromises = tickers.map((ticker) => rds.current?.subscribe(ticker))

    return () => {
      if (!rds.current) {
        tickers.forEach((ticker) => {
          cache.delete(ticker)
        })
        return
      }

      subsPromises.forEach(
        (promise) =>
          promise?.then((subscription) => {
            if (subscription) {
              rds.current?.unsubscribe(subscription)
            }
          })
      )
    }
  }, [tickers])

  if (APPLICATION !== "bloomberg") {
    return null
  }

  // tickerOrTickerMap check only to satisfy types. Real check is tickers.length because that is filtered data
  if (!tickers?.length || !tickerOrTickerMap) {
    return null
  }

  if (!projectData.size) {
    return null
  }

  if (typeof tickerOrTickerMap === "string") {
    const value = projectData.get(tickerOrTickerMap)

    if (!value) {
      return null
    }
    return { usd: value } as PricingInfoReturnType<T>
  }

  const priceMap: PriceDataType = {}

  tickerOrTickerMap.forEach(({ id }, ticker) => {
    const value = projectData.get(ticker)
    if (value) {
      priceMap[id] = { usd: value }
    }
  })

  return priceMap as PricingInfoReturnType<T>
}
