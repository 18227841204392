import { Logo } from "@tokenterminal/ui/Logo"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text } from "@tokenterminal/ui/Text"
import { apiPost } from "api/ApiCalls"
import { PageMeta } from "components/PageMeta"
import { APPLICATION, OAUTH_BLOOMBERG_CLIENT_ID } from "constants/app.mjs"
import { redirectUrl } from "modules/auth/oauth/bloomberg.api"
import { useApplicationSDK } from "modules/bloomberg/hooks"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { AuthType } from "types/ApiTypes"
import { useAuth } from "context/AuthContext"

const ERROR_NOT_BLOOMBERG = "Not running inside bloomberg"
const ERROR_AUTH_FAILED = "Authentication failed"

export default function BloombergSplash() {
  const router = useRouter()
  const [error, setError] = useState<string | null>(null)
  const [isMounted, setIsMounted] = useState(false)
  const { login } = useAuth()

  const sdk = useApplicationSDK()
  if (sdk) {
    sdk.currentComponent.setSize(
      Math.ceil(screen.width * 0.9),
      Math.ceil(screen.height * 0.9)
    )
    sdk.currentComponent.setPosition(screen.width * 0.05, screen.height * 0.05)
  }

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    if (!window.bb) {
      setError(ERROR_NOT_BLOOMBERG)
      return
    }

    // initial page
    if (!router.query.status) {
      import("modules/auth/oauth/bloomberg-sdk").then(({ default: auth }) => {
        // keep the page open for 3 seconds
        setTimeout(() => {
          return auth.OAuth2.signOn({
            clientId: OAUTH_BLOOMBERG_CLIENT_ID as string,
            redirectURI: redirectUrl,
            scopes: ["profile"],
            nonce: "",
          })
        }, 3000)
      })
    }

    if (router.query.status === "success" && router.query.token) {
      apiPost<AuthType>(
        "/users/token",
        {
          accessToken: router.query.token,
        },
        false,
        "v1"
      )
        .then(() => {
          login()
        })
        .then(() => {
          router.push("/terminal/")
        })
        .catch(() => {
          setError(ERROR_AUTH_FAILED)
        })
    }

    if (router.query.status === "failed") {
      setError(ERROR_AUTH_FAILED)
    }

    if (!router.query.status) {
      setIsMounted(true)
    }
  }, [login, router, router.isReady, router.query])

  if (APPLICATION !== "bloomberg") {
    // This should never be reached, but serves as a fallback in case 404 is removed or change or whatnot
    return <PageMeta noIndex />
  }

  // We start with a text component with a null character to prevent the
  // page from jumping around when the text changes
  let textComponent = (
    <Text
      dangerouslySetInnerHTML={{
        __html: isMounted ? "Loading..." : "&#10240;",
      }}
    />
  )

  // if we don't run from inside bloomberg the following is undefined
  if (error === ERROR_NOT_BLOOMBERG) {
    textComponent = (
      <Text color="error">
        You can only visit this page using the Bloomberg Terminal
      </Text>
    )
  }

  if (error === ERROR_AUTH_FAILED) {
    textComponent = (
      <Text color="error">
        Authentication failed, please contact{" "}
        <a href="mailto:people@tokenterminal.xyz" rel="nofollow">
          people@tokenterminal.xyz
        </a>
      </Text>
    )
  }

  return (
    <div>
      <PageMeta meta={{ title: "Bloomberg" }} noIndex />
      <Stack alignItems="center" justifyContent="center" height="100vh">
        <Logo width={400} />
        {textComponent}
      </Stack>
    </div>
  )
}

export const getStaticProps = () => {
  if (APPLICATION !== "bloomberg") {
    return {
      props: {},
      notFound: true,
    }
  }
  return {
    props: {},
  }
}
