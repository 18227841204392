import { useApplicationSDK } from "modules/bloomberg/hooks"
import Head from "next/head"
import { useRouter } from "next/router"

const checkCanonical = (canonical: string): string => {
  if (!canonical.startsWith("https://") || canonical.endsWith("/")) {
    throw new Error(`Invalid canonical url (${canonical})"`)
  }
  return canonical
}
export interface Metadata {
  title?: string
  description?: null | string
  canonical?: null | string
  url?: null | string
  noIndex?: boolean
}

interface Props {
  meta?: Metadata
  noIndex?: boolean
}

const formatMetaTitle = (title: string) => {
  if (title.trim().endsWith("| Token Terminal")) {
    // throw to ensure there's no misuse. Consider eg. if we wanna update the postfix at some point
    throw new Error(
      "Meta title postfixes are automatically set for consistency. Please dont add a manual one unless its a prefix."
    )
  }
  // Allow prefix for front-pages
  if (title.includes("Token Terminal |")) {
    return title
  }
  return `${title} | Token Terminal`
}

const selfCanonical = (asPath: string) => {
  const [plainPath] = asPath.split("?")
  // Remove trailing slash should only be necessary on frontpage
  return `https://tokenterminal.com${plainPath}`.replace(/\/$/, "")
}

function Bloomberg({ title }: { title?: string }) {
  const sdk = useApplicationSDK()

  if (sdk && title) {
    // move out of react rending cycle
    setTimeout(() => {
      try {
        sdk.currentComponent.setTitle(title)
      } catch (error) {
        console.warn(
          "Failed to set title for BB terminal. Maybe not in one: ",
          error
        )
      }
    }, 0)
  }

  return null
}

// NOTE: keys are set to override _app.tsx values for non native meta props. Apparently it's needed (at least in dev)
export const PageMeta = ({
  meta: {
    title,
    description,
    canonical: forcedCanonical,
    url,
    noIndex: metaNoIndex,
  } = {},
  noIndex,
}: Props) => {
  const { asPath } = useRouter()
  // Use passed canonical or create one. This is a SEO request/feature
  const canonical = forcedCanonical ?? selfCanonical(asPath)

  const shareUrl = url ?? canonical
  const formattedTitle = title && formatMetaTitle(title)

  return (
    <>
      <Bloomberg title={formattedTitle ?? title} />
      <Head>
        {formattedTitle && (
          <>
            <title>{formattedTitle}</title>
            <meta property="og:title" key="og:title" content={formattedTitle} />
            <meta
              name="twitter:title"
              key="twitter:title"
              content={formattedTitle}
            />
          </>
        )}
        {description && (
          <>
            <meta name="description" content={description} />
            <meta
              property="og:description"
              key="og:description"
              content={description}
            />
            <meta
              name="twitter:description"
              key="twitter:description"
              content={description}
            />
          </>
        )}
        {shareUrl && <meta property="og:url" key="og:url" content={shareUrl} />}
        {canonical && <link rel="canonical" href={checkCanonical(canonical)} />}

        {(noIndex || metaNoIndex) && <meta name="robots" content="noindex" />}
      </Head>
    </>
  )
}

PageMeta.displayName = "PageMeta"
